import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Icon, Strong, List, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				The Gentlemen’s Den Barbershop
			</title>
			<meta name={"description"} content={"Де традиція зустрічається з сучасним стилем"} />
			<meta property={"og:title"} content={"The Gentlemen’s Den Barbershop"} />
			<meta property={"og:description"} content={"Де традиція зустрічається з сучасним стилем"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				ПОСЛУГИ
			</Override>
		</Components.Header>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-16"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 60px 0px"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text as="p" font="--base" color="--orange" margin="0px 0px 8px 0px">
					Про нас та наші послуги
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					{" "}Мистецтво догляду за собою
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					sm-text-align="left"
				>
					The Gentlemen’s Den був заснований на вірі в те, що кожен чоловік заслуговує на простір, де він може відчувати себе впевнено, розслаблено і доглянуто. Наші засновники, досвідчені барбери з пристрастю до своєї справи, прагнули створити барбершоп, який вшановує традиції минулого, але приймає сучасні тренди.
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				lg-justify-content="center"
				lg-width="100%"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				justify-content="center"
				margin="0px 0px 36px 0px"
				position="relative"
			>
				<Image
					src="https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09:42:13.442Z"
					display="block"
					margin="0px 0px 0px 0px"
					border-radius="16px"
					height="400px"
					width="100%"
					object-fit="cover"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e8e778710018d28737/images/1-4.jpg?v=2024-08-12T09%3A42%3A13.442Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="block"
				flex-direction="column"
				grid-gap="25px"
				lg-margin="32px 0px 0px 0px"
			>
				<Box margin="0px 0px 2rem 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Наші Барбери: Майстри Своєї Справи
						</Strong>
						<br />
						<br />
						В центрі The Gentlemen’s Den стоїть наша команда барберів. Кожен член нашої команди ретельно відібраний за свої навички, досвід і відданість мистецтву барберства. Наші барбери – це не просто професіонали, це майстри, які пишаються кожною стрижкою, голінням і стилем, які вони виконують.
					</Text>
				</Box>
				<Box margin="0px 0px 2rem 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Наші Послуги: Індивідуальний Підхід
						</Strong>
						<br />
						<br />
						У The Gentlemen’s Den ми пропонуємо широкий спектр послуг з догляду, які охоплюють усі аспекти чоловічого догляду. Наші послуги включають:
						<br />
						Класичні стрижки: Традиційні та сучасні стилі, адаптовані до ваших уподобань.
						<br />
						Гоління з гарячим рушником: Розслаблююче та оновлююче гоління з використанням небезпечної бритви.
						<br />
						Підстригання та скульптурування бороди: Доводимо вашу бороду до досконалості.
						<br />
						Процедури для обличчя: Глибоке очищення та зволоження шкіри для підтримки її найкращого вигляду.
						<br />
						Фарбування волосся та бороди: Ніжне або сміливе, ми пропонуємо послуги з фарбування для покращення вашого образу.
					</Text>
				</Box>
				<Box margin="0px 0px 2rem 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						font="18px/27px --fontFamily-sans"
						color="--light"
						lg-max-width="720px"
					>
						<Strong>
							Наша Філософія: Якість Понад Кількість
						</Strong>
						<br />
						<br />
						У The Gentlemen’s Den ми віримо, що якість не повинна йти на компроміс. Від продуктів, які ми використовуємо, до технік, які ми застосовуємо, все вибирається з увагою, щоб наші клієнти отримували найкращий сервіс. Ми прагнемо зрозуміти ваші потреби та вподобання, пропонуючи персоналізовані поради та рекомендації для досягнення бажаного образу.
					</Text>
				</Box>
			</List>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="block"
				flex-direction="column"
				grid-gap="25px"
				lg-margin="28px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 2rem 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Наша Спільнота: Місце для Вас
						</Strong>
						<br />
						<br />
						The Gentlemen’s Den – це більше, ніж барбершоп, це спільнота. Ми створили простір, де чоловіки можуть зібратися разом, розслабитися та насолодитися дружньою атмосферою. Наші клієнти є в центрі всього, що ми робимо, і ми прагнемо будувати тривалі стосунки, засновані на довірі та повазі.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Приєднуйтесь до Спільноти The Gentlemen’s Den
						</Strong>
						<br />
						<br />
						Відчуйте різницю, яку робить професійний догляд. Забронюйте свою зустріч сьогодні та станьте частиною спільноти The Gentlemen’s Den.
					</Text>
				</Box>
			</List>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-1.jpg?v=2024-08-12T09:42:13.448Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Приєднуйтеся до спільноти The Gentlemen’s Den
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Відчуйте різницю, яку робить професійний догляд. Забронюйте свою зустріч сьогодні та станьте частиною спільноти The Gentlemen’s Den.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});